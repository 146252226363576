import '../stylesheets/style.scss';

window.onload = () => {
  const contentSections = Array.from(document.querySelectorAll('.content section'));
  const navItems = document.querySelectorAll('nav a');
  const contentTexts = [];

  contentSections.forEach((section) => {
    contentTexts.push({ id: section.id, content: section.innerHTML });
  });

  setTimeout(() => {
    const intro = document.querySelector('.intro');
    intro.classList.add('out');
  }, 2000);

  setTimeout(() => {
    const page = document.querySelector('.page');
    page.classList.add('show');
  }, 2000);

  navItems.forEach((item) => {
    item.onclick = (event) => {
      const href = item.href.split('#')[1];
      showOneSection(href, event);
    };
  });

  function showOneSection(id, event) {
    const contentSection = contentSections.find((section) => section.id === id);
    if (!contentSection) {
      return;
    }

    if (event) {
      event.preventDefault();
    }

    contentSections.forEach((section) => (section.style.display = 'none'));
    contentSection.innerHTML = '';
    contentSection.style.display = 'block';

    const contentText = contentTexts.find((item) => item.id === id);

    if (id === 'video') {
      contentSection.innerHTML = contentText.content;
      return;
    }

    let textPart = '';
    if (contentText) {
      for (let i = 0; i <= contentText.content.length; i++) {
        setTimeout(() => {
          textPart += contentText.content.charAt(i);
          contentSection.innerHTML = textPart;
        }, 5 * i);
      }
    }
  }
};
